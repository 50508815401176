import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';
import flockLogo from '../images/logo.svg';

const LogoContainer = styled.div`
    display: flex;
    justify-content: center;

    img {
        width: 150px;
    }
`;

const BodyContent = styled.div`
    font-size: 18px;
    max-width: 600px;
    margin: 0 auto;

    .message {
        font-size: 20px;
        margin-bottom: 2rem;
    }

    .center {
        text-align: center;
    }

    a {
        background-color: #61ffbd;
        border-radius: 3px;
        border: solid 1px black;
        padding: 8px 17px;
        color: black;
        text-decoration: none;
    }
`;

const SuccessPage = () => (
    <Layout>
        <SEO
            title="The app for fitness professionals"
            description="FLOCK.fitness is here to change how fitness professionals engage with their clients."
        />

        <LogoContainer>
            <Link to="/">
                <img src={flockLogo} />
            </Link>
        </LogoContainer>

        <BodyContent>
            <p className="message">
                Thanks for registering your interest, we'll be in touch with
                updates soon.
            </p>

            <div className="center">
                <a href="/">Go back</a>
            </div>
        </BodyContent>
    </Layout>
);

export default SuccessPage;
